import React, { useCallback, useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import {
  BarChart,
  Calendar as CalendarIcon,
  Check,
  ChevronLeft,
  ChevronRight,
  X as CloseIcon,
  Flame,
  Moon,
  PieChart,
  Settings,
  Sun,
  Sunrise,
  Sunset,
  Users,
  X,
} from "lucide-react";
import AuthForm from "./AuthForm";
import { useAuth } from "./context/AuthContext";
import { auth, db } from "./firebase";
import SettingsPanel from "./SettingsPanel";
import "./styles/variables.css";
import { fetchPrayerTimes, PrayerTimes } from "./api/prayerTimesApi";
import AddFamilyMember from "./components/AddFamilyMember";
import EditFamilyMember from "./components/EditFamilyMember";
import FamilyMembersList from "./components/FamilyMembersList";
import LoadingIndicator from "./components/LoadingIndicator";
import { fetchUserData } from "./utils/userUtils";

const calculationMethods = [
  { id: 0, name: "Shia Ithna-Ashari" },
  { id: 1, name: "University of Islamic Sciences, Karachi" },
  { id: 2, name: "Islamic Society of North America" },
  { id: 3, name: "Muslim World League" },
  { id: 4, name: "Umm Al-Qura University, Makkah" },
  { id: 5, name: "Egyptian General Authority of Survey" },
  { id: 7, name: "Institute of Geophysics, University of Tehran" },
  { id: 8, name: "Gulf Region" },
  { id: 9, name: "Kuwait" },
  { id: 10, name: "Qatar" },
  { id: 11, name: "Majlis Ugama Islam Singapura, Singapore" },
  { id: 12, name: "Union Organization islamic de France" },
  { id: 13, name: "Diyanet İşleri Başkanlığı, Turkey" },
  { id: 14, name: "Spiritual Administration of Muslims of Russia" },
  { id: 15, name: "Moonsighting Committee Worldwide" },
  { id: 16, name: "Dubai (unofficial)" },
];

interface LogoProps {
  className?: string;
}

interface PrayerSummary {
  Fajr: number;
  Dhuhr: number;
  Asr: number;
  Maghrib: number;
  Isha: number;
  completed: number;
  missed: number;
  remaining: number;
  total: number;
}

interface FamilyMember {
  id: string;
  name: string;
  is_admin: boolean;
  overallPrayerSummary: {
    total: number;
    completed: number;
    missed: number;
    streak: number;
    joinDate: string;
  };
  prayerSummaries: Record<string, any>;
}

const defaultPrayerTimes: PrayerTimes = {
  Fajr: "",
  Dhuhr: "",
  Asr: "",
  Maghrib: "",
  Isha: "",
};

const PrayerTimesApp = () => {
  const [countdown, setCountdown] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nextPrayer, setNextPrayer] = useState({ name: "", time: "" });
  const [prayerTimes, setPrayerTimes] =
    useState<PrayerTimes>(defaultPrayerTimes);
  const [showSettings, setShowSettings] = useState(false);
  const [location, setLocation] = useState(
    localStorage.getItem("prayerTimesLocation") || "",
  );
  const [calculationMethod, setCalculationMethod] = useState(
    localStorage.getItem("prayerTimesCalculationMethod") || "3",
  );
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const { user, loading, userData } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [prayerSummary, setPrayerSummary] = useState<PrayerSummary>({
    Fajr: 0,
    Dhuhr: 0,
    Asr: 0,
    Maghrib: 0,
    Isha: 0,
    completed: 0,
    missed: 0,
    remaining: 5,
    total: 5,
  });
  const [overallPrayerSummary, setOverallPrayerSummary] = useState<{
    total: number;
    completed: number;
    missed: number;
    streak: number;
    joinDate: string;
  }>({
    total: 0,
    completed: 0,
    missed: 0,
    streak: 0,
    joinDate: new Date().toISOString(),
  });
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([]);
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false);
  const [showEditFamilyMember, setShowEditFamilyMember] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<
    string | null
  >(null);
  const [memberToEdit, setMemberToEdit] = useState<FamilyMember | null>(null);

  const [selectedFamilyMemberIndex, setSelectedFamilyMemberIndex] = useState(0);

  const refreshUserData = useCallback(
    async (date: Date = new Date()) => {
      if (user) {
        try {
          const formattedDate = getFormattedDate(date);
          const userData = await fetchUserData(
            user.uid,
            formattedDate,
            selectedFamilyMemberIndex,
          );

          console.log("userData:", userData);
          setLocation(userData.location);
          setCalculationMethod(userData.calculationMethod.toString());
          setFamilyMembers(userData.familyMembers);
          setPrayerSummary(userData.prayerSummary);
          setOverallPrayerSummary(userData.overallPrayerSummary);
        } catch (error) {
          console.error("Error refreshing user data:", error);
        }
      }
    },
    [selectedFamilyMemberIndex, user],
  );

  useEffect(() => {
    refreshUserData(selectedDate);
  }, [refreshUserData, selectedDate]);

  const updateNextPrayer = useCallback(() => {
    const now = new Date();
    const prayerEntries = Object.entries(prayerTimes);

    for (let i = 0; i < prayerEntries.length; i++) {
      const [prayer, time] = prayerEntries[i];
      const prayerTime = new Date(now.toDateString() + " " + time);

      if (prayerTime > now) {
        setNextPrayer({ name: prayer, time: time });
        const timeDiff = prayerTime.getTime() - now.getTime();
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setCountdown(
          `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
        );
        return;
      }
    }

    // If no next prayer found today, set to first prayer of next day
    const [firstPrayer, firstTime] = prayerEntries[0];
    const nextDay = new Date(now);
    nextDay.setDate(nextDay.getDate() + 1);
    setNextPrayer({ name: firstPrayer, time: firstTime });
    const timeDiff =
      new Date(nextDay.toDateString() + " " + firstTime).getTime() -
      now.getTime();
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    setCountdown(
      `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
    );
  }, [prayerTimes]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      updateNextPrayer();
    }, 1000);
    return () => clearInterval(timer);
  }, [updateNextPrayer]);

  const fetchTimes = useCallback(async () => {
    if (!location) {
      setError("Please set a location in the settings.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const [lat, lon] = await getCoordinates(location);
      if (!lat || !lon) {
        setError("Unable to get coordinates for the given location.");
        setIsLoading(false);
        return;
      }

      const times = await fetchPrayerTimes(
        lat,
        lon,
        parseInt(calculationMethod),
        0,
      );
      setPrayerTimes(times);
    } catch (error) {
      console.error("Error fetching prayer times:", error);
      setError("Failed to fetch prayer times. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [location, calculationMethod]);

  useEffect(() => {
    fetchTimes();
  }, [fetchTimes]);

  const getCoordinates = async (
    location: string,
  ): Promise<[number, number]> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`,
      );
      const data = await response.json();
      if (data && data[0]) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
    return [0, 0];
  };

  const getPrayerIcon = (prayer: keyof PrayerTimes) => {
    switch (prayer) {
      case "Fajr":
        return <Sunrise className="w-6 h-6 text-pink-800" />;
      case "Dhuhr":
        return <Sun className="w-6 h-6 text-yellow-700" />;
      case "Asr":
        return <Sun className="w-6 h-6 text-blue-700" />;
      case "Maghrib":
        return <Sunset className="w-6 h-6 text-orange-700" />;
      case "Isha":
        return <Moon className="w-6 h-6 text-indigo-800" />;
      default:
        return null;
    }
  };

  const getPrayerBorderColor = (prayer: keyof PrayerTimes) => {
    switch (prayer) {
      case "Fajr":
        return "border-pink-400";
      case "Dhuhr":
        return "border-yellow-400";
      case "Asr":
        return "border-blue-400";
      case "Maghrib":
        return "border-orange-400";
      case "Isha":
        return "border-indigo-400";
      default:
        return "border-gray-300";
    }
  };

  const changeDate = (days: number) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const getFormattedDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const togglePrayerCompletion = async (prayer: string, completed: boolean) => {
    if (!user) return;
    const date = getFormattedDate(selectedDate);

    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const familyMembers = userData.familyMembers;
        const currentUser = familyMembers[selectedFamilyMemberIndex];

        if (currentUser) {
          if (!currentUser.prayerSummaries[date]) {
            currentUser.prayerSummaries[date] = {};
          }

          const previousStatus = currentUser.prayerSummaries[date][prayer];

          // Update prayer status
          currentUser.prayerSummaries[date][prayer] = completed ? 1 : -1;

          // Update overallPrayerSummary
          if (previousStatus === undefined) {
            // If it's a new entry, increment total and the appropriate count
            currentUser.overallPrayerSummary.total += 1;
            if (completed) {
              currentUser.overallPrayerSummary.completed += 1;
            } else {
              currentUser.overallPrayerSummary.missed += 1;
            }
          } else if (previousStatus !== (completed ? 1 : -1)) {
            // If the status has changed, update completed and missed counts
            if (completed) {
              currentUser.overallPrayerSummary.completed += 1;
              currentUser.overallPrayerSummary.missed -= 1;
            } else {
              currentUser.overallPrayerSummary.completed -= 1;
              currentUser.overallPrayerSummary.missed += 1;
            }
          }

          // Update streak
          const today = getFormattedDate(selectedDate);

          // Function to check if all prayers for a given day are completed
          const allPrayersCompletedForDay = (day: string) => {
            return (
              currentUser.prayerSummaries[day] &&
              Object.values(currentUser.prayerSummaries[day]).length === 5 &&
              Object.values(currentUser.prayerSummaries[day]).every(
                (status) => status === 1,
              )
            );
          };

          if (completed) {
            if (allPrayersCompletedForDay(today)) {
              // Check previous days
              let streakCount = 0;
              let checkDate = new Date(selectedDate);

              while (true) {
                if (allPrayersCompletedForDay(getFormattedDate(checkDate))) {
                  streakCount++;
                  checkDate.setDate(checkDate.getDate() - 1);
                } else {
                  break;
                }
              }

              currentUser.overallPrayerSummary.streak = streakCount;
            }
            // If not all prayers are completed for today, streak remains unchanged
          } else {
            // If any prayer is missed today, check if it breaks the streak
            if (!allPrayersCompletedForDay(today)) {
              currentUser.overallPrayerSummary.streak = 0;
            }
          }

          console.log(
            `Current streak: ${currentUser.overallPrayerSummary.streak} days`,
          );

          await updateDoc(userRef, {
            familyMembers: familyMembers,
          });

          // Fetch updated user data
          const updatedUserData = await fetchUserData(
            user.uid,
            date,
            selectedFamilyMemberIndex,
          );

          // Update local state with fetched data
          setPrayerSummary(updatedUserData.prayerSummary);
          setOverallPrayerSummary(updatedUserData.overallPrayerSummary);
          setFamilyMembers(updatedUserData.familyMembers);
        }
      }
    } catch (error) {
      console.error("Error updating prayer completion:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear local storage
      localStorage.removeItem("prayerTimesLocation");
      localStorage.removeItem("prayerTimesCalculationMethod");
      // Reset state
      setLocation("");
      setCalculationMethod("");
      setPrayerTimes(defaultPrayerTimes);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const addFamilyMember = async (newMember: FamilyMember) => {
    if (user) {
      const updatedFamilyMembers = [...familyMembers, newMember];
      setFamilyMembers(updatedFamilyMembers);

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        familyMembers: updatedFamilyMembers,
      });

      setShowAddFamilyMember(false);
    }
  };

  const editFamilyMember = async (id: string, newName: string) => {
    if (user) {
      const updatedFamilyMembers = familyMembers.map(member =>
        member.id === id ? { ...member, name: newName } : member
      );
      setFamilyMembers(updatedFamilyMembers);

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        familyMembers: updatedFamilyMembers,
      });

      setShowEditFamilyMember(false);
      setMemberToEdit(null);
    }
  };

  const removeFamilyMember = async (id: string) => {
    if (user) {
      const updatedFamilyMembers = familyMembers.filter(
        (member) => member.id !== id,
      );
      setFamilyMembers(updatedFamilyMembers);

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        familyMembers: updatedFamilyMembers,
      });

      if (selectedFamilyMember === id) {
        setSelectedFamilyMember(null);
      }
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setSelectedDate(newDate);
  };

  interface FeatureCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
  }

  const FeatureCard: React.FC<FeatureCardProps> = ({
    icon,
    title,
    description,
  }) => (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center">
      <div className="text-green-500 mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-gradient-to-r from-green-400 to-blue-500 p-4 shadow-md rounded-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Salat Tracker</h1>
          {user && (
            <div className="relative">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="text-white hover:text-gray-200 focus:outline-none transition duration-300"
              >
                <Settings size={24} />
              </motion.button>
              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <button
                    onClick={() => {
                      setShowSettings(true);
                      setShowUserMenu(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Settings
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Logout
                  </button>
                  <button
                    onClick={() => setShowUserMenu(false)}
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                  >
                    <CloseIcon size={16} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </header>

      <main className="container mx-auto mt-8 px-4">
        {user ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-col md:flex-row justify-between items-stretch mb-6 gap-4">
              <div className="bg-white rounded-xl shadow-md p-6 flex-1">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">
                  Select Date
                </h2>
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => changeDate(-1)}
                    className="text-green-500 hover:text-green-600 transition-colors"
                  >
                    <ChevronLeft size={24} />
                  </button>
                  <input
                    type="date"
                    value={selectedDate.toISOString().split("T")[0]}
                    onChange={handleDateChange}
                    className="bg-gray-100 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-green-300 flex-grow"
                  />
                  <button
                    onClick={() => changeDate(1)}
                    className="text-green-500 hover:text-green-600 transition-colors"
                  >
                    <ChevronRight size={24} />
                  </button>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 flex-1">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">
                  Next Prayer
                </h2>
                <div className="text-center">
                  <p className="text-3xl font-bold text-green-600 mb-2">
                    {nextPrayer.name}
                  </p>
                  <p className="text-4xl font-bold text-gray-800 mb-2">
                    {countdown}
                  </p>
                  <p className="text-lg text-gray-600">
                    at <span className="font-semibold">{nextPrayer.time}</span>
                  </p>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="text-center py-8">
                <LoadingIndicator />
                <p className="mt-4 text-base text-gray-600">
                  Loading prayer times...
                </p>
              </div>
            ) : error ? (
              <div className="text-center py-8 text-red-500 text-base">
                {error}
              </div>
            ) : prayerTimes && Object.keys(prayerTimes).length > 0 ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
                  {Object.entries(prayerTimes).map(([prayer, time]) => (
                    <motion.div
                      key={prayer}
                      className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition duration-300"
                      whileHover={{ scale: 1.03 }}
                    >
                      <div
                        className={`p-4 ${getPrayerBorderColor(prayer as keyof PrayerTimes)} border-t-4`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3">
                            {getPrayerIcon(prayer as keyof PrayerTimes)}
                            <div>
                              <p className="font-semibold text-lg text-gray-800">
                                {prayer}
                              </p>
                              <p className="text-gray-600">{time}</p>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            <button
                              onClick={() =>
                                togglePrayerCompletion(prayer, true)
                              }
                              className={`p-2 rounded-full ${
                                prayerSummary[
                                  prayer as keyof typeof prayerSummary
                                ] === 1
                                  ? "bg-green-500"
                                  : "bg-gray-200"
                              } transition-colors duration-200`}
                            >
                              <Check className="text-white" size={16} />
                            </button>
                            <button
                              onClick={() =>
                                togglePrayerCompletion(prayer, false)
                              }
                              className={`p-2 rounded-full ${
                                prayerSummary[
                                  prayer as keyof typeof prayerSummary
                                ] === -1
                                  ? "bg-red-500"
                                  : "bg-gray-200"
                              } transition-colors duration-200`}
                            >
                              <X className="text-white" size={16} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>

                {/* Add this new summary block */}
                <div className="mt-6 bg-white rounded-lg shadow-sm p-4">
                  <h3 className="text-lg font-semibold mb-3 text-gray-700 flex items-center">
                    <PieChart className="mr-2" size={20} />
                    {familyMembers[
                      selectedFamilyMemberIndex
                    ].name.toLowerCase() === "me"
                      ? "Your"
                      : `${familyMembers[selectedFamilyMemberIndex].name}'s`}{" "}
                    Daily Prayer Progress
                  </h3>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {[
                      {
                        label: "Offered",
                        value: prayerSummary.completed,
                        bgColor: "bg-green-100",
                        textColor: "text-green-800",
                      },
                      {
                        label: "Missed",
                        value: prayerSummary.missed,
                        bgColor: "bg-red-100",
                        textColor: "text-red-800",
                      },
                      {
                        label: "Upcoming",
                        value: prayerSummary.remaining,
                        bgColor: "bg-yellow-100",
                        textColor: "text-yellow-800",
                      },
                      {
                        label: "Total",
                        value: prayerSummary.total,
                        bgColor: "bg-blue-100",
                        textColor: "text-blue-800",
                      },
                    ].map(({ label, value, bgColor, textColor }) => (
                      <div
                        key={label}
                        className={`${bgColor} rounded-lg p-3 text-center`}
                      >
                        <p className={`text-2xl font-bold ${textColor}`}>
                          {value}
                        </p>
                        <p className={`text-sm ${textColor}`}>{label}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* New overall prayer summary */}
                <div className="mt-8 bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                    <BarChart className="mr-2" size={24} />
                    Lifetime Prayer Journey
                  </h3>
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                    {[
                      {
                        label: "Total Prayers",
                        value: overallPrayerSummary.total,
                        bgColor: "bg-blue-100",
                        textColor: "text-blue-800",
                      },
                      {
                        label: "Prayers Offered",
                        value: overallPrayerSummary.completed,
                        bgColor: "bg-green-100",
                        textColor: "text-green-800",
                      },
                      {
                        label: "Prayers Missed",
                        value: overallPrayerSummary.missed,
                        bgColor: "bg-red-100",
                        textColor: "text-red-800",
                      },
                      {
                        label: "Current Streak",
                        value: `${overallPrayerSummary.streak} days`,
                        bgColor: "bg-yellow-100",
                        textColor: "text-yellow-800",
                      },
                      {
                        label: "Streak Status",
                        value:
                          overallPrayerSummary.streak > 0
                            ? "🔥 Active"
                            : "Inactive",
                        bgColor:
                          overallPrayerSummary.streak > 0
                            ? "bg-orange-100"
                            : "bg-gray-100",
                        textColor:
                          overallPrayerSummary.streak > 0
                            ? "text-orange-800"
                            : "text-gray-800",
                      },
                    ].map(({ label, value, bgColor, textColor }) => (
                      <div
                        key={label}
                        className={`${bgColor} rounded-lg p-4 text-center`}
                      >
                        <p className={`text-3xl font-bold ${textColor}`}>
                          {typeof value === "number" ? value : value}
                        </p>
                        <p className={`text-sm ${textColor} mt-1`}>{label}</p>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-sm text-gray-600 flex items-center">
                    <CalendarIcon className="mr-2" size={16} />
                    Journey started:{" "}
                    {new Date(
                      overallPrayerSummary.joinDate,
                    ).toLocaleDateString()}
                  </div>
                  {overallPrayerSummary.streak > 0 && (
                    <div className="mt-2 text-sm text-green-600 flex items-center">
                      <Flame className="mr-2" size={16} />
                      {overallPrayerSummary.streak === 1
                        ? "MashaAllah! You've started your streak. Keep going!"
                        : `MashaAllah! You're on a ${overallPrayerSummary.streak}-day streak. Stay consistent!`}
                    </div>
                  )}
                </div>

                {/* Add Family Members section */}
                <div className="mt-8 bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                    <Users className="mr-2" size={24} />
                    Family Members
                  </h3>
                  <FamilyMembersList
                    familyMembers={familyMembers}
                    selectedMemberIndex={selectedFamilyMemberIndex}
                    onSelectMember={(index) => {
                      setSelectedFamilyMemberIndex(index);
                      refreshUserData(selectedDate);
                    }}
                    onRemoveMember={removeFamilyMember}
                    onEditMember={(id) => {
                      const member = familyMembers.find(m => m.id === id);
                      if (member) {
                        setMemberToEdit(member);
                        setShowEditFamilyMember(true);
                      }
                    }}
                  />
                  <button
                    onClick={() => setShowAddFamilyMember(true)}
                    className="mt-4 bg-gradient-to-r from-green-400 to-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:opacity-90 transition duration-300"
                  >
                    Add Family Member
                  </button>
                </div>
              </>
            ) : (
              <div className="text-center py-8 text-gray-600 text-base">
                No prayer times available. Please check your settings and try
                again.
              </div>
            )}
          </motion.div>
        ) : (
          <motion.div
            className="text-center py-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              Welcome to Salat Tracker
            </h2>
            <p className="mb-8 text-xl text-gray-600 max-w-2xl mx-auto">
              Your personal companion for maintaining a consistent prayer
              routine and strengthening your spiritual journey.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 max-w-4xl mx-auto">
              <FeatureCard
                icon={<CalendarIcon size={40} />}
                title="Prayer Time Calculations"
                description="Get accurate prayer times based on your location and preferred calculation method."
              />
              <FeatureCard
                icon={<BarChart size={40} />}
                title="Track Your Progress"
                description="Monitor your prayer consistency and build lasting habits."
              />
              <FeatureCard
                icon={<Users size={40} />}
                title="Family Tracking"
                description="Add family members and encourage each other in your spiritual goals."
              />
            </div>
            <button
              onClick={() => setShowAuthForm(true)}
              className="bg-gradient-to-r from-green-400 to-blue-500 text-white px-8 py-4 rounded-full font-semibold hover:opacity-90 transition duration-300 text-lg shadow-lg"
            >
              Get Started - Login / Register
            </button>
            <p className="mt-6 text-gray-600">
              Join thousands of Muslims worldwide in enhancing their prayer
              life.
            </p>
          </motion.div>
        )}
      </main>

      {showSettings && (
        <SettingsPanel
          location={location}
          setLocation={setLocation}
          calculationMethod={parseInt(calculationMethod)}
          setCalculationMethod={(method) =>
            setCalculationMethod(method.toString())
          }
          calculationMethods={calculationMethods}
          setShowSettings={setShowSettings}
        />
      )}

      {showAuthForm && (
        <AuthForm
          onClose={() => setShowAuthForm(false)}
          refreshUserData={refreshUserData}
        />
      )}

      {showAddFamilyMember && (
        <AddFamilyMember
          onAdd={addFamilyMember}
          onClose={() => setShowAddFamilyMember(false)}
        />
      )}

      {showEditFamilyMember && memberToEdit && (
        <EditFamilyMember
          member={memberToEdit}
          onEdit={editFamilyMember}
          onClose={() => {
            setShowEditFamilyMember(false);
            setMemberToEdit(null);
          }}
        />
      )}
    </div>
  );
};

export default PrayerTimesApp;
