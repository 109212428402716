import React from "react";
import PrayerTimesApp from "./islamic-prayer-times-app";

function App() {
  return (
    <div className="App flex justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl px-4 py-8">
        <PrayerTimesApp />
      </div>
    </div>
  );
}

export default App;
