import React, { useState } from "react";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import { motion } from "framer-motion";
import { Eye, EyeOff, Info, Lock, Mail, User, X } from "lucide-react";
import { auth } from "./firebase";
import { initializeUserDocument } from "./utils/userUtils";

interface AuthFormProps {
  onClose: () => void;
  refreshUserData: () => Promise<void>; // Add this line
}

const AuthForm: React.FC<AuthFormProps> = ({ onClose, refreshUserData }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/\d/)) strength++;
    if (password.match(/[^a-zA-Z\d]/)) strength++;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      // Set the persistence based on the rememberMe checkbox
      await setPersistence(
        auth,
        rememberMe ? browserLocalPersistence : browserSessionPersistence,
      );

      let userCredential: UserCredential;
      if (isLogin) {
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );
      } else {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );
        await initializeUserDocument(userCredential.user.uid);
      }

      // Refresh user data after successful login/registration
      await refreshUserData();

      onClose();
    } catch (error: any) {
      console.error("Authentication error:", error);
      if (error.code) {
        setError(`Error (${error.code}): ${error.message}`);
        console.error(`Detailed error: ${JSON.stringify(error)}`);
      } else if (error instanceof Error) {
        setError(`Error: ${error.name} - ${error.message}`);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Please check your inbox.");
    } catch (error: any) {
      setError(`Error: ${error.message}`);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ scale: 0.9, y: 50 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.9, y: 50 }}
        className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden relative"
      >
        <div className="bg-gradient-to-r from-green-400 to-blue-500 p-6 text-white relative">
          <h2 className="text-3xl font-bold mb-2">
            {isLogin ? "Welcome Back" : "Join Salat Tracker"}
          </h2>
          <p className="text-sm opacity-80">
            {isLogin
              ? "Log in to access your prayer times and tracking features."
              : "Create an account to start tracking your prayers and stay connected with your salat."}
          </p>
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white hover:text-gray-200 transition-colors"
            aria-label="Close"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div className="relative">
            <Mail
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>
          <div className="relative">
            <Lock
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordStrength(e.target.value);
              }}
              className="w-full p-3 pl-10 pr-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          {!isLogin && (
            <div>
              <div className="h-2 bg-gray-200 rounded-full mb-1">
                <div
                  className={`h-full rounded-full ${
                    passwordStrength === 0
                      ? "w-0"
                      : passwordStrength === 1
                        ? "w-1/4 bg-red-500"
                        : passwordStrength === 2
                          ? "w-1/2 bg-yellow-500"
                          : passwordStrength === 3
                            ? "w-3/4 bg-blue-500"
                            : "w-full bg-green-500"
                  }`}
                ></div>
              </div>
              <p className="text-xs text-gray-600">
                Password strength:{" "}
                <span
                  className={`font-semibold ${
                    passwordStrength === 0
                      ? "text-gray-500"
                      : passwordStrength === 1
                        ? "text-red-500"
                        : passwordStrength === 2
                          ? "text-yellow-500"
                          : passwordStrength === 3
                            ? "text-blue-500"
                            : "text-green-500"
                  }`}
                >
                  {passwordStrength === 0
                    ? "None"
                    : passwordStrength === 1
                      ? "Weak"
                      : passwordStrength === 2
                        ? "Fair"
                        : passwordStrength === 3
                          ? "Good"
                          : "Strong"}
                </span>
              </p>
            </div>
          )}
          {isLogin && (
            <button
              type="button"
              onClick={handleForgotPassword}
              className="text-sm text-blue-500 hover:text-blue-600 transition-colors"
            >
              Forgot Password?
            </button>
          )}
          {error && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded"
              role="alert"
              aria-live="assertive"
            >
              <p className="font-bold">Error</p>
              <p>{error}</p>
            </div>
          )}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-sm text-gray-600">
              Remember me
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white p-3 rounded-lg font-semibold hover:opacity-90 transition duration-300 flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : null}
            {isLogin ? "Login" : "Register"}
          </button>
        </form>

        <div className="px-6 pb-6">
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="w-full text-center text-sm text-gray-600 hover:text-gray-800 transition duration-300"
          >
            {isLogin
              ? "Need an account? Register"
              : "Already have an account? Login"}
          </button>
        </div>

        <div className="bg-gray-100 p-4 text-sm text-gray-600">
          <div className="flex items-start space-x-2">
            <Info size={20} className="flex-shrink-0 mt-1 text-blue-500" />
            <p>
              {isLogin
                ? "Logging in allows you to track your prayers, set reminders, and access personalized insights to enhance your spiritual journey."
                : "By creating an account, you'll be able to track your daily prayers, view prayer times for your location, and stay connected with your spiritual goals."}
            </p>
          </div>
        </div>

        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>
      </motion.div>
    </motion.div>
  );
};

export default AuthForm;
