import React, { useState } from "react";
import { Edit2, User, X } from "lucide-react";
import ConfirmationModal from "./ConfirmationModal";

interface FamilyMember {
  id: string;
  name: string;
  is_admin: boolean;
}

interface FamilyMembersListProps {
  familyMembers: FamilyMember[];
  selectedMemberIndex: number;
  onSelectMember: (index: number) => void;
  onRemoveMember: (id: string) => void;
  onEditMember?: (id: string) => void;
}

const FamilyMembersList: React.FC<FamilyMembersListProps> = ({
  familyMembers,
  selectedMemberIndex,
  onSelectMember,
  onRemoveMember,
  onEditMember,
}) => {
  const [memberToDelete, setMemberToDelete] = useState<string | null>(null);

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    e.stopPropagation();
    setMemberToDelete(id);
  };

  return (
    <div className="space-y-2">
      {familyMembers.map((member, index) => (
        <div
          key={member.id}
          className={`flex items-center justify-between p-2 rounded-lg cursor-pointer ${
            selectedMemberIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
          }`}
          onClick={() => onSelectMember(index)}
        >
          <div className="flex items-center">
            <User className="mr-2" size={20} />
            <span>{member.name}</span>
          </div>

          {!member.is_admin && (
            <div className="flex gap-2">
              <button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onEditMember?.(member.id);
                }}
                className="p-1.5 text-blue-500 hover:text-blue-700 hover:bg-blue-100 rounded-full transition-colors"
              >
                <Edit2 size={18} />
              </button>
              <button
                onClick={(e) => handleDeleteClick(e, member.id)}
                className="p-1.5 text-red-500 hover:text-red-700 hover:bg-red-100 rounded-full transition-colors"
              >
                <X size={18} />
              </button>
            </div>
          )}
        </div>
      ))}
      <ConfirmationModal
        isOpen={memberToDelete !== null}
        onClose={() => setMemberToDelete(null)}
        onConfirm={() => {
          if (memberToDelete) {
            onRemoveMember(memberToDelete);
          }
        }}
        title="Delete Family Member"
        message="Are you sure you want to remove this family member? This action cannot be undone."
      />
    </div>
  );
};

export default FamilyMembersList;
