import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCTCeiDQfLwSNYUAqNZcCmXS40STp1z-_o",
  authDomain: "prayer-time-tracker.firebaseapp.com",
  projectId: "prayer-time-tracker",
  storageBucket: "prayer-time-tracker.appspot.com",
  messagingSenderId: "447704352043",
  appId: "1:447704352043:web:cb4b5b98c680fb3633b16e",
  measurementId: "G-R8XJKK7ENK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { app, auth, db, analytics };
