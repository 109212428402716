import axios from "axios";

// Define PrayerTimes interface here instead of importing it
export interface PrayerTimes {
  Fajr: string;
  Dhuhr: string;
  Asr: string;
  Maghrib: string;
  Isha: string;
}

const API_BASE_URL = "https://api.aladhan.com/v1/timings";

export async function fetchPrayerTimes(
  latitude: number,
  longitude: number,
  method: number,
  school: number,
): Promise<PrayerTimes> {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        latitude,
        longitude,
        method,
        school,
      },
    });

    const timings = response.data.data.timings;
    return {
      Fajr: timings.Fajr,
      Dhuhr: timings.Dhuhr,
      Asr: timings.Asr,
      Maghrib: timings.Maghrib,
      Isha: timings.Isha,
    };
  } catch (error) {
    console.error("Error fetching prayer times:", error);
    throw error;
  }
}
