import React, { useState } from "react";
import { X } from "lucide-react";

interface FamilyMember {
  id: string;
  name: string;
  is_admin: boolean;
  overallPrayerSummary: {
    total: number;
    completed: number;
    missed: number;
    streak: number;
    joinDate: string;
  };
  prayerSummaries: Record<string, any>;
}

interface AddFamilyMemberProps {
  onAdd: (member: FamilyMember) => void;
  onClose: () => void;
}

const AddFamilyMember: React.FC<AddFamilyMemberProps> = ({
  onAdd,
  onClose,
}) => {
  const [name, setName] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim()) {
      const newMember: FamilyMember = {
        id: Date.now().toString(),
        name: name.trim(),
        is_admin: false,
        overallPrayerSummary: {
          total: 0,
          completed: 0,
          missed: 0,
          streak: 0,
          joinDate: new Date().toISOString(),
        },
        prayerSummaries: {},
      };
      onAdd(newMember);
      setName("");
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden">
        <div className="bg-gradient-to-r from-green-400 to-blue-500 p-6 text-white relative">
          <h3 className="text-2xl font-bold mb-2">Add Family Member</h3>
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white hover:text-gray-200 transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter family member's name"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
          />
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white p-3 rounded-lg font-semibold hover:opacity-90 transition duration-300"
          >
            Add Member
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddFamilyMember;
