import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { X } from "lucide-react"; // Add this import
import { useAuth } from "./context/AuthContext";
import { db } from "./firebase";
import LocationDropdown from "./LocationDropdown";

interface SettingsPanelProps {
  location: string;
  setLocation: (location: string) => void;
  calculationMethod: number;
  setCalculationMethod: (method: number) => void;
  calculationMethods: Array<{ id: number; name: string }>;
  setShowSettings: (show: boolean) => void;
}

const SettingsPanel: React.FC<SettingsPanelProps> = ({
  location,
  setLocation,
  calculationMethod,
  setCalculationMethod,
  calculationMethods,
  setShowSettings,
}) => {
  const [tempLocation, setTempLocation] = useState(location);
  const [tempCalculationMethod, setTempCalculationMethod] =
    useState(calculationMethod);
  const { user } = useAuth();

  const handleSave = async () => {
    setLocation(tempLocation);
    setCalculationMethod(tempCalculationMethod);
    setShowSettings(false);

    // Save to localStorage
    localStorage.setItem("prayerTimesLocation", tempLocation);
    localStorage.setItem(
      "prayerTimesCalculationMethod",
      tempCalculationMethod.toString(),
    );

    // Save to Firestore
    if (user) {
      try {
        const userRef = doc(db, "users", user.uid);

        console.log(
          "User settings updated in Firestore",
          tempLocation,
          tempCalculationMethod,
          user.uid,
        );

        await setDoc(
          userRef,
          {
            location: tempLocation,
            calculationMethod: tempCalculationMethod,
          },
          { merge: true },
        );
        console.log("User settings updated in Firestore");
      } catch (error) {
        console.error("Error updating user settings in Firestore:", error);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden relative">
        <div className="bg-gradient-to-r from-green-400 to-blue-500 p-6 text-white">
          <h2 className="text-2xl font-bold mb-2">Settings</h2>
          <button
            onClick={() => setShowSettings(false)}
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        <div className="p-6 space-y-4">
          <LocationDropdown
            location={tempLocation}
            setLocation={setTempLocation}
          />
          <div className="mt-4 pt-3">
            <label
              htmlFor="calculationMethod"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Calculation Method
            </label>
            <select
              id="calculationMethod"
              value={tempCalculationMethod}
              onChange={(e) =>
                setTempCalculationMethod(parseInt(e.target.value))
              }
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            >
              <option value="">Select a calculation method</option>
              {calculationMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-end space-x-3 p-6 bg-gray-50">
          <button
            onClick={() => setShowSettings(false)}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-green-400 to-blue-500 rounded-md hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
