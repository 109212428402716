import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export const initializeUserDocument = async (userId: string) => {
  const userRef = doc(db, "users", userId);
  const newUserData = {
    location: "",
    calculationMethod: 3,
    familyMembers: [
      {
        name: "Me",
        is_admin: true,
        overallPrayerSummary: {
          total: 0,
          completed: 0,
          missed: 0,
          streak: 0,
          joinDate: new Date().toISOString(),
        },
        prayerSummaries: {},
      },
    ],
  };
  await setDoc(userRef, newUserData);
};

export const fetchUserData = async (
  userId: string,
  date: string,
  selectedFamilyMemberIndex: number = 0,
) => {
  try {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const familyMembers = userData.familyMembers || [];

      // Ensure the selected index is within bounds
      const validIndex = Math.min(
        Math.max(selectedFamilyMemberIndex, 0),
        familyMembers.length - 1,
      );
      const selectedMember = familyMembers[validIndex];

      if (selectedMember) {
        const datePrayerSummary = selectedMember.prayerSummaries[date] || {};

        return {
          location: userData.location || "",
          calculationMethod: userData.calculationMethod || 3,
          familyMembers: familyMembers,
          currentUser: selectedMember,
          prayerSummary: {
            Fajr: datePrayerSummary.Fajr || 0,
            Dhuhr: datePrayerSummary.Dhuhr || 0,
            Asr: datePrayerSummary.Asr || 0,
            Maghrib: datePrayerSummary.Maghrib || 0,
            Isha: datePrayerSummary.Isha || 0,
            completed: Object.values(datePrayerSummary).filter(
              (val: any) => val === 1,
            ).length,
            missed: Object.values(datePrayerSummary).filter(
              (val: any) => val === -1,
            ).length,
            remaining:
              5 -
              Object.values(datePrayerSummary).filter((val: any) => val !== 0)
                .length,
            total: 5,
          },
          overallPrayerSummary: selectedMember.overallPrayerSummary,
        };
      }
    }
    throw new Error("User data not found");
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
